<div class="container">
    <div class="area">
        <label for="lebel"  style="color:chocolate">Text</label>
        <input type="text" name="textfield" class="form-control" ><br>
        <button type="button" class="btn btn-primary">Push</button>
        <button type="button" class="btn btn-danger">Pop</button>
        <button type="button" class="btn btn-warning">isEmpty</button>
        <button type="button" class="btn btn-info">isFull</button>

        <ul class="border">
            <li>a</li>
        </ul>
    </div>
</div>
