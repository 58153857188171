import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  profileForm: FormGroup;

  constructor(private fb: FormBuilder) { 
    this.profileForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phoneNo: ['', [Validators.required, Validators.pattern('[0-9]+')]],
      pinCode: ['', [Validators.required, Validators.pattern('[0-9]+')]],
      address: this.fb.group({
        state: ['', [Validators.required]],
        district: ['', [Validators.required]],
        village: ['', [Validators.required]],
        post: ['', [Validators.required]],
      }),
      skills: this.fb.array([this.newSkill()], Validators.required),
    });
  }

  ngOnInit(): void {
  }

  // Method to create a new skill form control
  newSkill(): FormGroup {
    return this.fb.group({
      skill: ['', Validators.required],
      module: ['', Validators.required],
    });
  }

  // Getter to access the skills form array
  get skills(): FormArray {
    return this.profileForm.get('skills') as FormArray;
  }

  // Method to add a new skill control
  addSkill() {
    this.skills.push(this.newSkill());
  }

  // Method to remove a skill control
  removeSkill(index: number) {
    this.skills.removeAt(index);
  }

  // Method to submit the form
  onSubmit() {
    if (this.profileForm.valid) {
      console.log(this.profileForm.value);
    }
  }

}
