
<div class="main">
  <div class="top-header">
    <div class="hrea1">
      <span><mat-icon>share</mat-icon></span>
      <span>Rated 4.7 On Google</span>
    </div>
    <div class="hrea2">
      <span>Call Us +9739667399</span>
      <span><mat-icon>file_copy</mat-icon>View Quate</span>
    </div>
  </div>
    <div class="header">
      <mat-toolbar color="primary" class="mat-elevation-z8">
        <!-- <span><mat-icon>eco</mat-icon>LetsPlay</span> -->
        <a href="#"><img src="./assets/img/logo.png" width="200"></a>
      
        <span class="example-spacer"></span> 
      
        <a mat-button routerLink="home">Home</a>
        <a mat-button routerLink="./about">About Us</a>
        <a mat-button>By Category</a>
        <a mat-button>By Brand</a>
        <a mat-button>Download</a>
        <a mat-button>Blog</a>
        <a mat-button>Contact Us</a>
        <a mat-button>
          <mat-icon>search</mat-icon>
        </a>
      </mat-toolbar>
    </div>
  
</div>
