<footer>
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <h5>Company</h5>
                <ul class="list-unstyled">
                    <li><a href="#" class="text-white">About Us</a></li>
                    <li><a href="#" class="text-white">Careers</a></li>
                    <li><a href="#" class="text-white">Press</a></li>
                </ul>
            </div>
            <div class="col-md-4">
                <h5>Contact</h5>
                <ul class="list-unstyled">
                    <li><a href="#" class="text-white">Contact Us</a></li>
                    <li><a href="#" class="text-white">Support</a></li>
                    <li><a href="#" class="text-white">FAQ</a></li>
                </ul>
            </div>
            <div class="col-md-4">
                <h5>Follow Us</h5>
                <ul class="list-unstyled">
                    <li><a href="#" class="text-white">Facebook</a></li>
                    <li><a href="#" class="text-white">Twitter</a></li>
                    <li><a href="#" class="text-white">Instagram</a></li>
                </ul>
            </div>
        </div>
        <hr class="bg-light">
        <div class="row">
            <div class="col-12 text-center">
                <p>&copy; 2024 Your Company. All rights reserved.</p>
            </div>
        </div>
    </div>
</footer>
    <!-- <footer>
        <p>Copyright &copy; 2024 <span><mat-icon>eco</mat-icon>MajeDar</span> All Rights Reserved.</p>
    </footer> -->
