<!-- <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
    <div class="container">
            <div class="row">
              <div class="col-md-4 mt-3 mb-3">
                <label for="firstName" class="form-label">First Name:</label>
                <input id="firstName" class="form-control" formControlName="firstName" />
              </div>

              <div class="col-md-4 mt-3 mb-3">
                <label for="lastName" class="form-label">Last Name:</label>
                <input id="lastName" class="form-control" formControlName="lastName" />
              </div>

              <div class="col-md-4 mt-3 mb-3">
                <label for="email" class="form-label">Email:</label>
                <input id="email" class="form-control" formControlName="email" />
              </div>

              <div class="col-md-4 mt-3 mb-3">
                <label for="phoneNo" class="form-label">Phone No:</label>
                <input id="phoneNo" class="form-control" formControlName="phoneNo" />
              </div>

              <div class="col-md-4 mt-3 mb-3">
                <label for="pinCode" class="form-label">Pin Code:</label>
                <input id="pinCode" class="form-control" formControlName="pinCode" />
              </div>
            </div>

            <div formGroupName="address" class="row">
                <h3>Address</h3>
                <div class="col-md-4 mt-3 mb-3">
                  <label for="state" class="form-label">State:</label>
                  <input id="state" class="form-control" formControlName="state" />
                </div>

                <div class="col-md-4 mt-3 mb-3">
                  <label for="district" class="form-label">District:</label>
                  <input id="district" class="form-control" formControlName="district" />
                </div>

                <div class="col-md-4 mt-3 mb-3">
                  <label for="village" class="form-label">Village:</label>
                  <input id="village" class="form-control" formControlName="village" />
                </div>

                <div class="col-md-4 mt-3 mb-3">
                  <label for="post" class="form-label">Post:</label>
                  <input id="post" class="form-control" formControlName="post" />
                </div>
            </div>

            <div formArrayName="skills">
                <h3>Skills</h3>
                <div class="skills" *ngFor="let skill of skills.controls; let i = index" [formGroupName]="i">
                  <div class="row">
                      <div class="col-md-5 mt-3 mb-3 skills">
                          <input class="form-control" formControlName="skill" placeholder="Enter skill" />
                      </div>
                      <div class="col-md-5 mt-3 mb-3 skills">
                          <input class="form-control" formControlName="module" placeholder="Enter module" />
                      </div>
                      <div class="col-md-2 mt-3 mb-3">
                          <button type="button" class="btn btn-danger" (click)="removeSkill(i)">Remove</button>
                      </div>
                  </div>
                </div>
                <div class="col-md-12 mt-3 mb-3">
                    <button type="button" class="btn btn-primary" (click)="addSkill()">Add Skill</button>
                </div>
            </div>

            <button type="submit" class="btn btn-primary">Submit</button>
    </div>
</form> -->

<div class="bnnar">
    <!-- <img src="./assets/img/popo.webp"> -->
    <!-- <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img class="d-block w-100" src="./assets/img/popo.webp">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="./assets/img/popo.webp">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="./assets/img/popo.webp">
          </div>
        </div>
    </div> -->
    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img class="d-block w-100" src="./assets/img/door.webp">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="./assets/img/door.webp">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="./assets/img/popo.webp">
          </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
</div>
<section class="content">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1>COLLECTIONS</h1>

            </div>
        </div>
        <div class="row field">
            <div class="col-md-6">
                <a><img src="./assets/img/slider1.webp"></a>
                <h1>Activities</h1>
                
            </div>
            <div class="col-md-3">
                <a><img src="./assets/img/a.jpg" style="width: 100%; height: 549px;"></a>
                <h1>Store</h1>
                
            </div>
            <div class="col-md-3">
                <a><img src="./assets/img/b.jpg" style="width: 100%; height: 549px;"></a>
                <h1>Initiatives</h1>
                
            </div>
        </div>
        <div class="row field">
            <div class="col-md-3">
                <a><img src="./assets/img/slider1.webp" style="width: 100%; height: 549px;" ></a>
                <h1>Activities</h1>
                
            </div>
            <div class="col-md-3">
                <a><img src="./assets/img/a.jpg" style="width: 100%; height: 549px;"></a>
                <h1>Store</h1>
                
            </div>
            <div class="col-md-3">
                <a><img src="./assets/img/b.jpg" style="width: 100%; height:549px;"></a>
                <h1>Initiatives</h1>
                
            </div>
            <div class="col-md-3">
                <a><img src="./assets/img/a.jpg" style="width: 100%; height:549px;"></a>
                <h1>Store</h1>
                
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="about-img">
                    <a><img src="./assets/img/slider1.webp"></a>
                </div>
            </div>
            <div class="col-md-6 made">
                <div class="about-content">
                    <h1>ABOUT US</h1>
                    <span>H.Tambawala was founded over 25 years ago</span>
                    <p>
                        with the intent of bringing to the market a new concept of upgraded decorative hardware at moderate prices. Since 1994, H.Tambawala has supplied the industry with architectural hardware that is stylishly designed yet exceptionally functional. Backed by product innovation and a promise to provide personalized customer service, H.Tambawala has become a name recognized for excellence among design professionals in the high-end residential and hospitality industries.
                    </p>
                    <span class="rd">Read More</span>
                </div>
            </div>
        </div>
    </div>

    <div class="row field poop">
        <div class="col-md-4">
            <a><img src="./assets/img/slider1.webp" style="width: 100%; height: 400px;" ></a>
            
        </div>
        <div class="col-md-4">
            <a><img src="./assets/img/a.jpg" style="width: 100%; height: 400px;"></a>
            
        </div>
        <div class="col-md-4">
            <a><img src="./assets/img/b.jpg" style="width: 100%; height:400px;"></a>
            
        </div>
    </div>

    <div class="row pp">
        <div class="col-md-4">
            <div class="asd">
                <p>PULL HANDLE 55205 MSN</p>
                <a href=""> ADD TO QUOTE</a>
            </div>
        </div>
        <div class="col-md-4">
            <div class="asd">
                <p>PULL HANDLE 55205 MSN</p>
                <a href=""> ADD TO QUOTE</a>
            </div>
        </div>
        <div class="col-md-4">
            <div class="asd">
                <p>PULL HANDLE 55205 MSN</p>
                <a href=""> ADD TO QUOTE</a>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <a><img src="./assets/img/slider1.webp" style="width: 100%; height: 400px;" ></a>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-4">
                    <a><img src="./assets/img/11.jpg" style="width: 100%;"></a>
                </div>
                <div class="col-md-4">
                    <a><img src="./assets/img/12.png" style="width: 100%;"></a>
                </div>
                <div class="col-md-4">
                    <a><img src="./assets/img/13.png" style="width: 100%;"></a>
                </div>
                <div class="col-md-4">
                    <a><img src="./assets/img/14.png" style="width: 100%;"></a>
                </div>
                <div class="col-md-4">
                    <a><img src="./assets/img/15.png" style="width: 100%;"></a>
                </div>
                <div class="col-md-4">
                    <a><img src="./assets/img/16.png" style="width: 100%;"></a>
                </div>
                <div class="col-md-4">
                    <a><img src="./assets/img/11.jpg" style="width: 100%;"></a>
                </div>
                <div class="col-md-4">
                    <a><img src="./assets/img/12.png" style="width: 100%;"></a>
                </div>
                <div class="col-md-4">
                    <a><img src="./assets/img/13.png" style="width: 100%;"></a>
                </div>
            </div>
        </div>
    </div>
</section>




